// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 用户路由 31个
const user = [
  {
    path: '/user',
    component: () => import('@/pages/user/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('个人中心'),
      ssg: {}
    },
    redirect: '/user/info',
    children: [
      {
        path: 'info',
        component: () => import('@/pages/user/Info.vue'),
        meta: {
          title: t('账户总览'),
          ssg: {}
        }
      },
      {
        path: 'security',
        component: () => import('@/pages/user/Security.vue'),
        meta: {
          title: t('安全设置'),
          ssg: {}
        }
      },
      {
        path: 'preferences',
        component: () => import('@/pages/user/Preferences.vue'),
        meta: {
          auth: 'login',
          title: t('偏好设置')
        }
      },
      {
        path: 'card',
        component: () => import('@/pages/user/Card.vue'),
        meta: {
          title: t('我的卡片')
        }
      },
      // {
      //   path: 'preferences',
      //   component: () => import('@/pages/user/Preferences.vue'),
      //   meta: {
      //     title: t('偏好设置')
      //   }
      // },
      {
        path: 'kyc',
        component: () => import('@/pages/user/Kyc.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'equipment',
        component: () => import('@/pages/user/Equipment.vue'),
        meta: {
          title: t('可信设备管理'),
          ssg: {}
        }
      },
      {
        path: 'bind/:type',
        component: () => import('@/pages/user/Bind.vue'),
        meta: {
          title: t('安全设置'),
          ssg: {}
        }
      },
      {
        path: 'change_bind/:type',
        component: () => import('@/pages/user/ChangeBind.vue'),
        meta: {
          title: t('安全设置'),
          ssg: {}
        }
      },
      {
        path: 'resetpassword',
        component: () => import('@/pages/user/ResetPassword.vue'),
        meta: {
          title: t('安全设置'),
          ssg: {}
        }
      },
      {
        path: 'fish-code',
        component: () => import('@/pages/user/FishCode.vue'),
        meta: {
          title: t('安全设置'),
          ssg: {}
        }
      },
      {
        path: 'senior-auth',
        component: () => import('@/pages/user/SeniorAuth.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'base-auth',
        component: () => import('@/pages/user/BaseAuth.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'tax',
        component: () => import('@/pages/user/KycTax.vue'),
        meta: {
          title: t('财务信息'),
          ssg: {}
        }
      },
      {
        path: 'kycaudit',
        component: () => import('@/pages/user/KycAudit.vue'),
        meta: {
          title: t('审核中'),
          ssg: {}
        }
      },
      {
        path: 'kycJumio',
        component: () => import('@/pages/user/KycJumio.vue'),
        meta: {
          title: t('人脸识别'),
          ssg: {}
        }
      },
      {
        path: 'kycaddress',
        component: () => import('@/pages/user/KycAddress.vue'),
        meta: {
          title: t('地址信息'),
          ssg: {}
        }
      },

      {
        path: 'knowledgeytest',
        component: () => import('@/pages/user/KycKnowledgeTest.vue'),
        meta: {
          title: t('虚拟资产知识测试'),
          ssg: {}
        }
      },
      {
        path: 'kycriskappraisal',
        component: () => import('@/pages/user/KycRiskAppraisal.vue'),
        meta: {
          title: t('风险测评'),
          ssg: {}
        }
      },
      {
        path: 'kycupgradepi',
        component: () => import('@/pages/user/UpgradePi.vue'),
        meta: {
          title: t('个人专业投资者申请'),
          ssg: {}
        }
      },
      {
        path: 'kycupdatepi',
        component: () => import('@/pages/user/UpdatePi.vue'),
        meta: {
          title: t('专业投资者更新认证'),
          ssg: {}
        }
      },
      {
        path: 'hashkeycustody',
        component: () => import('@/pages/user/HashkeyCustody.vue'),
        meta: {
          title: t('开通 HashKey Xpert')
        }
      },
      {
        path: 'riskResult',
        component: () => import('@/pages/user/KycRiskResult.vue'),
        meta: {
          title: t('风险测评结果'),
          ssg: {}
        }
      },
      {
        path: 'kycassets',
        component: () => import('@/pages/user/KycAssets.vue'),
        meta: {
          title: t('资产证明'),
          ssg: {}
        }
      },
      {
        path: 'agreement',
        component: () => import('@/pages/user/KycAgreement.vue'),
        meta: {
          title: t('协议签署'),
          ssg: {}
        }
      },
      {
        path: 'baseauthenterprise',
        component: () => import('@/pages/user/BaseEnterprise.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'kycauthorizatio',
        component: () => import('@/pages/user/KycAuthorizatio.vue'),
        meta: {
          title: t('授权资料'),
          ssg: {}
        }
      },
      {
        path: 'kycJumioenterprise',
        component: () => import('@/pages/user/KycJumioEnterprise.vue'),
        meta: {
          title: t('人脸识别'),
          ssg: {}
        }
      },
      {
        path: 'kycother',
        component: () => import('@/pages/user/KycOtherFiles.vue'),
        meta: {
          title: t('其他材料'),
          ssg: {}
        }
      },
      {
        path: 'kycUnderReview',
        component: () => import('@/pages/user/KycUnderReview.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'KycBank',
        component: () => import('@/pages/user/KycBank.vue'),
        meta: {
          title: t('银行信息认证'),
          ssg: {}
        }
      },
      {
        path: 'kycoffline',
        component: () => import('@/pages/user/KycOffLine.vue'),
        meta: {
          title: t('线下认证')
        }
      },
      {
        path: 'kycofflinesignature',
        component: () => import('@/pages/user/KycOfflineSignature.vue'),
        meta: {
          title: t('线下认证'),
          ssg: {}
        }
      },
      {
        path: 'kycofflinesignatureaudit',
        component: () => import('@/pages/user/KycOfflineSignatureAudit.vue'),
        meta: {
          title: t('线下认证'),
          ssg: {}
        }
      },
      {
        path: 'senior-auth/:state',
        component: () => import('@/pages/user/SeniorAuth.vue'),
        meta: {
          title: t('身份认证'),
          ssg: {}
        }
      },
      {
        path: 'invite',
        component: () => import('@/pages/user/Invite.vue'),
        meta: {
          class: {
            layout: 'invite-box',
            header: 'invite-header'
          },
          props: { header: { border: false } },
          title: 'hashkey referral program',
          description: 'Get up to 40%rebate when you invite a friend',
          ssg: {}
        }
      },
      {
        path: 'message-center',
        component: () => import('@/pages/message-center/Index.vue'),
        meta: {
          title: t('message_center'),
          ssg: {}
        }
      },
      {
        path: 'sub-account',
        component: () => import('@/pages/user/SubAccount.vue'),
        meta: {
          title: t('子账户'),
          ssg: {}
        }
      },
      {
        path: 'sub-api',
        component: () => import('@/pages/user/SubApi.vue'),
        meta: {
          title: t('子账户API管理'),
          ssg: {}
        }
      },
      {
        path: 'operator',
        component: () => import('@/pages/user/Operator.vue'),
        meta: {
          title: t('授权交易员管理'),
          ssg: {}
        }
      },
      {
        path: 'permission-management',
        component: () => import('@/pages/user/PermissionManagement.vue'),
        meta: {
          title: t('权限管理'),
          ssg: {}
        }
      },
      {
        path: 'csv-statement',
        component: () => import('@/pages/user/AccountStatement.vue'),
        meta: {
          title: t('statement_csv_account'),
          ssg: {}
        }
      }
    ]
  },
  {
    path: '/user/start-jumio',
    component: () => import('@/pages/user/StartCertification.vue'),
    meta: {
      title: t('身份认证'),
      auth: 'login',
      layout: 'blank',
      ssg: {}
    }
  },
  {
    path: '/user/start-sumsub',
    component: () => import('@/pages/user/StartSumsubCertification.vue'),
    meta: {
      title: t('身份认证'),
      auth: 'login',
      layout: 'default',
      ssg: {}
    }
  },
  {
    path: '/user/h5/fee-rate',
    component: () => import('@/pages/hskFee/h5/Index.vue'),
    meta: {
      title: t('我的费率'),
      props: { header: { border: false } }
    },
    redirect: isPC() ? '/user/fee-rate' : ''
  },
  {
    path: '/user/fee-rate',
    component: () => import('@/pages/hskFee/web/Index.vue'),
    meta: {
      theme: 'dark',
      layout: 'default',
      title: t('会员等级-t'),
      props: {
        header: {
          class: 'm-width-1440',
          theme: 'dark'
        },
        footer: {
          class: 'm-width-1440',
          theme: 'dark'
        }
      }
    },
    redirect: !isPC() ? '/user/h5/fee-rate' : ''
  },
  {
    path: '/unsubscribe',
    component: () => import('@/pages/unsubscribe/web/Index.vue'),
    meta: {
      theme: 'dark',
      layout: 'default',
      title: t('取消订阅'),
      props: {
        header: {
          class: 'm-width-1440',
          theme: 'dark'
        },
        footer: {
          class: 'm-width-1440',
          theme: 'dark'
        }
      }
    },
    redirect: !isPC() ? '/h5/unsubscirbe' : ''
  },
  {
    path: '/h5/unsubscirbe',
    component: () => import('@/pages/unsubscribe/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('取消订阅'),
      ssg: {}
      // layout: 'default',
      //   auth: 'login'
    }
  },
  {
    path: '/signoff',
    component: () => import('@/pages/signoff/index.vue'),
    meta: {
      title: t('signoff_account_reverse'),
      layout: 'default',
      auth: 'login',
      ssg: {}
    }
  }
];

export default user;
