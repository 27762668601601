<template>
  <el-dialog
    v-model="dialogVisible"
    title="Waring"
    width="400px"
    :modal="true"
    :show-close="false"
    :before-close="handleClose"
    :close-on-click-modal="type != 3"
    :close-on-press-escape="type != 3"
    class="warningCfmDialog"
  >
    <div class="header flex-row flex-row-space-between">
      <div class="icon-warning" />
      <div v-if="type!=3" class="icon-close" @click="handleClose" />
    </div>
    <template v-if="type===1">
      <p class="name text-lg-sb gray-900 mt16">
        {{ $t('您的登录状态已过期') }}
      </p>
      <hk-button
        class="false-primary-lg h44 base-white w-full mt32"
        @click="goLogin"
      >
        {{ $t('我知道了') }}
      </hk-button>
    </template>
    <template v-if="type===2">
      <p class="name text-lg-sb gray-900 mt16">
        {{ $t('IP不支持') }}
      </p>
      <p class="detail text-sm-r gray-600 mt4">
        {{ t('由于您当前所处的位置属于受限制的管辖区，不建议您访问我们的平台服务。') }}
      </p>
      <hk-button
        class="false-primary-lg h44 base-white w-full mt32"
        @click="handleClose"
      >
        {{ $t('我知道了') }}
      </hk-button>
    </template>
    <template v-if="type===3">
      <p class="name text-lg-m gray-900 mt16">
        {{ $t('会话超时') }}
      </p>
      <p class="name text-xs-r gray-600 mt4">
        {{ $t('很抱歉，您的登录已超时，请重新输入密码或') }}
        <span class="re-login text-sm-m primary-500 ml8" @click="reLogin">{{ $t('重新登录') }}</span>
      </p>
      <p class="text-sm-m gray-700 mt16">
        {{ account }}
      </p>

      <div>
        <el-form
          ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-position="top"
          :hide-required-asterisk="true"
          @submit.prevent @keyup.enter="validateForm(loginFormRef)"
        >

          <el-form-item prop="password" :error="pwdError" :class="{'session-timeout-pwd': language == 'en-US'}">
            <hk-input
              v-model.trim="loginForm.password" class="input-md mt6" :placeholder="t('请输入登录密码')"
              :type="passType"
            >
              <template #suffix>
                <span v-if="passType === 'password'" class="login-icon-eye-closed" @click="switchPass('text')"/>
                <span v-if="passType === 'text'" class="login-icon-eye" @click="switchPass('password')"/>
                <span
                  v-if="loginForm.password.replace(/\s/g, '')" class="login-icon-closed ml8"
                  @click="clearPassword"
                />
              </template>
            </hk-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="mt32 flex-row items-center">
        <hk-button class="w170 false-sg-md gray-700" @click="handleClose">
          {{ t('取消') }}
        </hk-button>
        <hk-button
          class="false-primary-md base-white w170" :loading="loginLoading"
          @click="validateForm(loginFormRef)"
        >{{ t('登录') }}
        </hk-button>
      </div>
    </template>
  </el-dialog>
  <SecurityTimeout
    :visible="showSVerifyDlg"
    :loading="isSubmitting"
    send-type="2"
    @confirm="onConfirmSecurity"
    @up-visible="doCancelSecurity"
  />
</template>

<script lang="ts" setup>
import { getLanguage } from '@/lang/i18n';
import { useUserStore } from '@/store/User';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import HkInput from '@c/hk-element/HkInput.vue';
import HkButton from '@c/hk-element/HkButton.vue';
import SecurityTimeout from '@c/common/SecurityTimeout.vue';
import { FormInstance, FormRules } from 'element-plus';
import { vaildator } from '@/utils/validator';
import { toast } from '@/utils/toast';
import { ucApi } from '@/config/api';
import sha256 from 'js-sha256';
const { t } = useI18n();
const language = getLanguage();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const emits = defineEmits(['up-visible']);
const props = defineProps({
  visible: Boolean,
  type: {
    type: Number,
    default: 1
  }
});
const dialogVisible = computed(() => {
  return props.visible;
});
const handleClose = () => {
  userStore.changeLoginStatus(false); // 关闭登录状态过期弹窗
  userStore.changeOutStatus(false); // 关闭会话超时弹窗
  emits('up-visible', false);
  if (props.type === 3) {
    localStorage.removeItem('user_login_expire'); // 删除会话超时的标记
    userStore.logOut();
  }
};
const goLogin = () => {
  userStore.changeLoginStatus(false); // 关闭登录状态过期弹窗
  if (userStore.isLogin) {
    // 登出
    userStore.logOut(true);
  }
  // 跳转登录页，保留参数
  router.replace({
    path: '/login',
    params: route.params,
    query: route.query,
    hash: route.hash
  });
};
const reLogin = () => {
  userStore.changeOutStatus(false); // 关闭会话超时弹窗
  userStore.logOutAndRedirect();
};

const pwdError = ref('');
const request_id = ref('');
const loginLoading = ref(false);
// 安全验证
const showSVerifyDlg = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);
// 密码输入框类型
const passType = ref('password');
// form表单ref名
const loginFormRef = ref<FormInstance>();
// form表单名
const loginForm = reactive({
  password: ''
});

const validatePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    pwdError.value = '';
    callback();
  }
  if (value && !vaildator.validPassword(value)) {
    callback(new Error(t('密码8-50位字符，必须包含大小写字母和数字')));
  } else {
    callback();
  }
};

// form表单规则
const loginFormRules = reactive<FormRules>({
  password: [{ validator: validatePass, trigger: 'blur' }]
});

// 设置密码显示
const switchPass = (type: string) => {
  passType.value = type;
};

// 清空密码
const clearPassword = () => {
  loginForm.password = '';
  loginFormRef.value?.clearValidate(['password']);
};

// 表单校验
const validateForm = async(formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate(async(valid, fields) => {
    if (valid) {
      if (!loginForm.password) {
        pwdError.value = t('请输入密码');
        return;
      }
      console.log(userStore.activeCurrentTime);
      // if (userStore.activeCurrentTime == 3) {
      //   // time2
      //   showSVerifyDlg.value = true;
      // } else {
      //   // time1
      //   loginSubmit({});
      // }
      showSVerifyDlg.value = true;
    } else {
      console.log('error submit!', fields);
    }
  });
};

// 登录提交
const loginSubmit = async(data: any) => {
  loginLoading.value = true;
  let values: any = {
    // 密码登录
    password: sha256(loginForm.password),
    request_id: request_id.value,
    handle_login_token: false
  };
  if (data?.verifyCaseType) {
    values = {
      ...values,
      email_order_id: data?.emailOrderId,
      email_code: data?.emailCode,
      verify_code_type: data?.verifyCaseType,
      ga_code: data?.gaCode,
      mobile_order_id: data?.mobileOrderId,
      mobile_code: data?.mobileCode,
      auth_type: (data?.gaCode || data?.mobileCode) && data?.authType
    };
  }

  let userInfo: any = await ucApi.authLoginV2(values);
  if (userInfo.code == 200) {
    // 关闭登录状态过期弹窗
    loginLoading.value = false;
    userStore.changeOutStatus(false); // 关闭会话超时弹窗
    emits('up-visible', false);
    localStorage.removeItem('user_login_expire'); // 删除会话超时的标记
    // 重置定时器
    userStore.changeCurrentTime(1); // 计时器设置成1
    if (location.pathname.includes('/spot')) {
      userStore.checkUserActive(true);
    } else {
      userStore.checkUserActive();
    }
  } else {
    loginLoading.value = false;
	  toast.error(userInfo.message);
  }
};

/**
 * 提交2FA接口后，判定提现是否最终成功
 * @param data
 */
const onConfirmSecurity = async(data: any) => {
  loginSubmit(data);
};

const doCancelSecurity = () => {
  showSVerifyDlg.value = false;
};

// 账户信息
const account = computed(() => {
  if (userStore.userinfo.registerType === 2) {
    return userStore.userinfo.email;
  } else {
    return userStore.userinfo.mobile;
  }
});

// 检查用户request id
const checkRequestId = async() => {
  let res: any = await ucApi.getRequestId();
  if (res.code == 200 && res.data && (res.data != '0' || (res.data == '0' && userStore?.userinfo?.userId))) {
    // request id
    request_id.value = res.data;
    // 存储用户会话过期标志
    localStorage.setItem('user_login_expire', true);
  } else {
    handleClose();
  }
};

onMounted(() => {
  if (props.type === 3) {
    checkRequestId();
  }
});
</script>
  <style lang="scss">
  .warningCfmDialog {
    border-radius: 12px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      border-radius: 12px;
      background: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      padding: 24px;
      text-align: left;
      .icon-warning {
        width: 48px;
        height: 48px;
        background: url(@/assets/img/common/icon-warning.svg) center/cover no-repeat;
      }
      .icon-close {
        width: 24px;
        height: 24px;
        background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
      }
    }
  }
  </style>

<style lang="scss" scoped>

.login-icon-eye {
  width: 24px;
  height: 24px;
  background: url(@/assets/img/icon/icon-eye.png) 0 0/24px 24px no-repeat;
  cursor: pointer;
}
.login-icon-closed {
  width: 18px;
  height: 18px;
  background: url(@/assets/img/icon/icon-closed.png) 0 0/18px 18px no-repeat;
  cursor: pointer;
}
.login-icon-eye-closed {
  width: 24px;
  height: 24px;
  background: url(@/assets/img/icon/icon-eye-closed.png) 0 0/24px 24px
    no-repeat;
  cursor: pointer;
}
.re-login {
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -24px;
    margin: auto 0;
    width: 20px;
    height: 20px;
    background: url(@/assets/img/icon/icon-chevron-right.svg) center/cover no-repeat;
  }
}
.session-timeout-pwd.is-error {
  margin-bottom: 44px;
}

</style>
