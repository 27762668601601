export const RESOURCE_ASSET = 'auth.asset';
export const RESOURCE_ASSET_OVER = 'auth.asset.over';
export const RESOURCE_ASSET_OVER_ACCOUNT = 'auth.asset.over.account';
export const RESOURCE_ASSET_OVER_ACCOUNT_VIEW = 'auth.asset.over.account.view';
export const RESOURCE_ASSET_OVER_ACCOUNT_DETAIL = 'auth.asset.over.account.detail';
export const RESOURCE_ASSET_OVER_ACCOUNT_DETAIL_VIEW = 'auth.asset.over.account.detail.view';
export const RESOURCE_ASSET_OVER_ACCOUNT_DETAIL_EXPORT = 'auth.asset.over.account.detail.export';
export const RESOURCE_ASSET_CUSTODY = 'auth.asset.custody';
export const RESOURCE_ASSET_CUSTODY_VIEW = 'auth.asset.custody.view';
export const RESOURCE_ASSET_CUSTODY_RECORDS = 'auth.asset.custody.records';
export const RESOURCE_ASSET_CUSTODY_RECORDS_VIEW = 'auth.asset.custody.records.view';
export const RESOURCE_ASSET_CUSTODY_RECORDS_EXPORT = 'auth.asset.custody.records.export';
export const RESOURCE_ASSET_CUSTODY_RECORDS_ADDRESS = 'auth.asset.custody.records.address';
export const RESOURCE_ASSET_CUSTODY_RECORDS_CANCEL = 'auth.asset.custody.records.cancel';
export const RESOURCE_ASSET_CUSTODY_WHITELIST = 'auth.asset.custody.whitelist';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_VIEW = 'auth.asset.custody.whitelist.view';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_ADD = 'auth.asset.custody.whitelist.add';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_ADD_DEPOSIT = 'auth.asset.custody.whitelist.add.deposit';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_ADD_WITHDRAW = 'auth.asset.custody.whitelist.add.withdraw';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_ADD_DEPOSIT_WITHDRAW = 'auth.asset.custody.whitelist.add.deposit_withdraw';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_EDIT = 'auth.asset.custody.whitelist.edit';
export const RESOURCE_ASSET_CUSTODY_WHITELIST_DELETE = 'auth.asset.custody.whitelist.delete';
export const RESOURCE_ASSET_FIAT = 'auth.asset.fiat';
export const RESOURCE_ASSET_FIAT_VIEW = 'auth.asset.fiat.view';
export const RESOURCE_ASSET_FIAT_VIEW_OVER = 'auth.asset.fiat.view.over';
export const RESOURCE_ASSET_FIAT_VIEW_EXCHANGE = 'auth.asset.fiat.view.exchange';
export const RESOURCE_ASSET_FIAT_RECORDS = 'auth.asset.fiat.records';
export const RESOURCE_ASSET_FIAT_RECORDS_VIEW = 'auth.asset.fiat.records.view';
export const RESOURCE_ASSET_FIAT_RECORDS_EXPORT = 'auth.asset.fiat.records.export';
export const RESOURCE_ASSET_FIAT_BANK = 'auth.asset.fiat.bank';
export const RESOURCE_ASSET_FIAT_BANK_VIEW = 'auth.asset.fiat.bank.view';
export const RESOURCE_ASSET_FIAT_BANK_ADD = 'auth.asset.fiat.bank.add';
export const RESOURCE_ASSET_FIAT_BANK_EDIT = 'auth.asset.fiat.bank.edit';
export const RESOURCE_ASSET_FIAT_BANK_DELETE = 'auth.asset.fiat.bank.delete';
export const RESOURCE_ASSET_TRADING = 'auth.asset.trading';
export const RESOURCE_ASSET_BROKERAGE = 'auth.asset.brokerage';
export const RESOURCE_ASSET_BROKERAGE_VIEW = 'auth.asset.brokerage.view';
export const RESOURCE_ASSET_BROKERAGE_RECORDS = 'auth.asset.brokerage.records';
export const RESOURCE_ASSET_OPT = 'auth.asset.opt';
export const RESOURCE_ASSET_OPT_VIEW = 'auth.asset.opt.view';
export const RESOURCE_ASSET_OPT_RECORDS = 'auth.asset.opt.records';
export const RESOURCE_ASSET_PREFUND = 'auth.asset.prefund';
export const RESOURCE_ASSET_PREFUND_VIEW = 'auth.asset.prefund.view';
export const RESOURCE_ASSET_PREFUND_TRANSFER = 'auth.asset.prefund.transfer';
export const RESOURCE_ASSET_PREFUND_TRANSFER_VIEW = 'auth.asset.prefund.transfer.view';
export const RESOURCE_ASSET_PREFUND_TRANSFER_EXPORT = 'auth.asset.prefund.transfer.export';
export const RESOURCE_ASSET_ETF = 'auth.asset.etf';
export const RESOURCE_ASSET_ETF_VIEW = 'auth.asset.etf.view';
export const RESOURCE_ASSET_ETF_SUBSCRIPTION_REDEMPTION = 'auth.asset.etf.subscribe_redemptive';
export const RESOURCE_USER = 'auth.user';
export const RESOURCE_USER_REFERRAL = 'auth.user.referral';
export const RESOURCE_USER_TRADER = 'auth.user.trader';
export const RESOURCE_USER_TRADER_VIEW = 'auth.user.trader.view';
export const RESOURCE_USER_TRADER_ADD = 'auth.user.trader.add';
export const RESOURCE_USER_TRADER_DEACTIVATE = 'auth.user.trader.deactivate';
export const RESOURCE_USER_PERMISSION = 'auth.user.permission';
export const RESOURCE_USER_PERMISSION_VIEW = 'auth.user.per mission.view';
export const RESOURCE_USER_PERMISSION_CONFIRM = 'auth.user.permission.confirm';
export const RESOURCE_ASSET_FINANCE = 'auth.asset.finance';//理财账户
export const RESOURCE_ASSET_FINANCE_SUBSCRIBE = 'auth.asset.finance.over.subscribe';//理财账户申购
export const RESOURCE_ASSET_FINANCE_OVER = 'auth.asset.finance.over';//理财账户持仓总揽
export const RESOURCE_ASSET_FINANCE_OVER_VIEW = 'auth.asset.finance.over.view';//理财账户 概览
export const RESOURCE_ASSET_FINANCIAL_RECORD = 'auth.asset.finance.record';//理财账户 账户记录
export const RESOURCE_ASSET_FINANCE_RECORD_EXPORT = 'auth.asset.finance.record.export';//理财账户 账户记录 导出
export const RESOURCE_ASSET_FINANCE_RECORD_VIEW = 'aauth.asset.finance.record.view';//理财账户 账户记录 概览
export const RESOURCE_ASSET_FINANCE_REDEMPTION='auth.asset.finance.over.redemption';//理财账户 赎回
export const RESOURCE_ASSET_FINANCE_RECORD_CANCEL='auth.asset.finance.record.cancel';//理财账户 账户记录 撤销

