// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC, isHK } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 通用路由 10个
const common = [
  {
    path: '/',
    component: isHK()
      // exchange.hashkey.com 专属首页
      ? () => import('@/pages/home/IndexHK.vue')
      : () => import('@/pages/home/Index.vue'),
    meta: {
      layout: 'default',
      props: { header: { theme: 'dark', border: false }, footer: { theme: 'dark', border: false } },
      title: isHK()
        ? t('exchange_home_meta_title')
        : t('HashKey Exchange - 香港持牌虚拟资产交易所 - 零售客户可用港币、美元购买 BTC 和 ETH 等多种数字货币'),
      description: isHK()
        ? t('exchange_home_meta_description')
        : t('在 HashKey，使用银行卡以港币或美元购买比特币、以太坊等多种虚拟资产。立即开户，赢取1000 HSK！加入 HashKey Exchange，畅享便捷的数字货币交易，感受极速的出入金体验！'),
      ssg: {
        preload: [commonStore.getSymbols, commonStore.getCurrency]
      }
    },
    redirect: !isPC() ? '/h5/hkhome' : ''
  },
  {
    path: '/h5/hkhome',
    component: () => import('@/pages/home/hkh5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey Exchange - 香港持牌虚拟资产交易所 - 零售客户可用港币、美元购买 BTC 和 ETH 等多种数字货币'),
      description: t('在 HashKey，使用银行卡以港币或美元购买比特币、以太坊等多种虚拟资产。立即开户，赢取1000 HSK！加入 HashKey Exchange，畅享便捷的数字货币交易，感受极速的出入金体验！'),
      ssg: {}
    }
  },
  {
    path: '/h5/agreement',
    component: () => import('@/pages/home/hkh5/Agreement.vue'),
    meta: {
      layout: 'h5Default',
      title: t('现在注册 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('立即注册HashKey Exchange，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/register',
    component: () => import('@/pages/register/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在注册 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('立即注册HashKey Exchange，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/register/invite',
    component: () => import('@/pages/register/invite/Invite.vue'),
    meta: {
      // 此页面为h5的被邀请人注册页面
      layout: 'h5Default',
      title: t('现在注册 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('立即注册HashKey Exchange，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/h5/wx/download',
    component: () => import('@/pages/download/wechat/Index.vue'),
    meta: {
      layout: '',
      title: t('现在下载 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('下载HashKey Exchange应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/download',
    component: () => import('@/pages/appDownload/web/Index.vue'),
    meta: {
      layout: 'default',
      props: { footer: { theme: 'dark', margin: false } },
      title: t('现在下载 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('下载HashKey Exchange应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    },
    redirect: !isPC() ? '/h5/download' : ''
  },
  {
    path: '/h5/imToken',
    component: () => import('@/pages/imToken/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('现在下载 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('下载HashKey Exchange应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/h5/download',
    component: () => import('@/pages/appDownload/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('现在下载 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('下载HashKey Exchange应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    }
  },
  {
    path: '/login',
    component: () => import('@/pages/login/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在登录 - HashKey Exchange - 买币更方案，存币更安心'),
      description: t('安全登录 HashKey Exchange，开始进行数字加密货币交易：比特币(BTC)、以太坊(ETH)等热门加密货币的交易平台。'),
      ssg: {}
    }
  },
  {
    path: '/modify-password',
    component: () => import('@/pages/login/ModifyPassword.vue'),
    meta: {
      layout: 'login',
      title: t('修改密码'),
      ssg: {}
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/reset-password/Layout.vue'),
    meta: {
      layout: 'login',
      title: t('忘记密码'),
      ssg: {}
    }
  },
  {
    path: '/auth',
    component: () => import('@/pages/auth/Index.vue'),
    meta: {
      layout: '',
      title: t('现在登录 - HashKey Exchange - 买币更方案，存币更安心'),
      ssg: {}
    }
  },
  {
    path: '/support-fee',
    component: () => import('@/pages/support/feeIndex.vue'),
    meta: {
      layout: 'default',
      title: t('support-fee.page-title'),
      props: {
        header: {
          class: 'm-width-1440 mobile-header'
        },
        default: {
          isPC: true
        },
        footer: {
          class: 'm-width-1440 mobile-footer'
        }
      },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/support-fee' : ''
  },
  {
    path: '/h5/support-fee',
    component: () => import('@/pages/support/feeIndex.vue'),
    meta: {
      layout: 'h5Default',
      title: t('手续费率'),
      ssg: {}
    },
    props: {
      default: {
        isPC: false
      }
    },
    redirect: isPC() ? '/support-fee' : ''
  },
  {
    path: '/official-verification',
    component: () => import('@/pages/official-verification/web/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HashKey 官方渠道验证'),
      ssg: {}
    },
    redirect: !isPC() ? '/h5/official-verification' : ''
  },
  {
    path: '/h5/official-verification',
    component: () => import('@/pages/official-verification/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey 官方渠道验证'),
      ssg: {}
    },
    redirect: !isPC() ? '' : '/official-verification'
  },
  {
    path: '/contactUs',
    component: () => import('@/pages/contactUs/Index.vue'),
    meta: {
      layout: 'default',
      title: t('common-tdk-contactUS-title'),
      description: t('common-tdk-contactUS-description'),
      keywords: t('common-tdk-contactUS-keywords'),
      ssg: {}
    }
  },
  {
    path: '/blog',
    component: () => import('@/pages/blog/index.vue'),
    meta: {
      layout: 'default',
      // props: { footer: { margin: false } },
      title: t('blog_title'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    }
    // redirect: !isPC() ? '/h5/blog' : ''
  },
  {
    path: '/blog/:id',
    component: () => import('@/pages/blog/detail.vue'),
    meta: {
      layout: 'default',
      // props: { footer: { margin: false } },
      title: t('blog_title'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    }
  },
  {
    path: '/h5/blog',
    component: () => import('@/pages/blog/h5/index.vue'),
    meta: {
      layout: 'h5Default',
      // props: { footer: { margin: false } },
      title: t('blog_title'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    }
    // redirect: isPC() ? '/blog' : ''
  },
  {
    path: '/h5/blog/:id',
    component: () => import('@/pages/blog/h5/detail.vue'),
    meta: {
      layout: 'blank',
      title: t('blog_title'),
      keywords: t('HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所'),
      ssg: {}
    }
  }
];

export default common;
