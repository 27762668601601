import { i18n } from '@/lang/i18n';
// import { pinia } from '@/store';
// import { useCommonStore } from '@/store/common';
// import { isPC } from '@/utils/tools';

const { t } = i18n.global;
// const commonStore = useCommonStore(pinia);

const financial = [
  {
    path: '/financial/index',
    component: () => import('@/pages/financial/index.vue'),
    meta: {
      layout: 'default',
      // auth: 'login',
      title: t('理财')
      // ssg: {},
      // userRolePermission: RESOURCE_ASSET_OVER
    }
  },
  {
    path: '/financial/detail/:id',
    alias: '/financial/detail/:id',
    component: () => import('@/pages/financial/detail.vue'),
    meta: {
      layout: 'default',
      // auth: 'login',
      title: t('理财')
      // ssg: {},
      // userRolePermission: RESOURCE_ASSET_OVER
    }
  }
];

export default financial;
